import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken = localStorage.getItem("access_token");

    let url = "";

    if (req.url.includes("assets")) {
      return next.handle(req);
    }

    if (req.url.startsWith("http") || req.url.startsWith("/oauth")) {
      url = req.url;
    } else {
      url = `${environment.loApiUrl}/api/${req.url}`;
    }

    if (
      accessToken &&
      !req.url.startsWith("https://srakod.se") &&
      !req.url.startsWith(`${environment.loApiUrl}/simple-captcha-handler`)
    ) {
      const cloned = req.clone({
        url: url,
        headers: req.headers.set("Authorization", "Bearer " + accessToken),
      });

      return next.handle(cloned);
    } else {
      const cloned = req.clone({
        url: url,
      });
      return next.handle(cloned);
    }
  }
}
